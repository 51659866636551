import { AbstractRx } from './pdmp.type';

export const mapToPdmpNonParticipatingStates = ({
  data: { pdmpNonParticipatingStates },
}) => pdmpNonParticipatingStates.map(({ shortName }) => shortName);

export const mapToRxCartReadyToSignResult = ({
  loading: loading,
  data: {
    markRxCartReadyToSign: { success },
  },
}): boolean => success;

// replace last comma with ampersand. eg. CA, IL, NY -> CA, IL & NY
export const formatStateList = (states: string[]) =>
  states.join(', ').replace(/,([^,]*)$/, ' &' + '$1');

// use rx.require2Fa for renewals & change -- excludes denied renewals
// use rx.dispensableControlledSubstance for pendingNewRx
export const hasControlledSubstanceFilter = (rx: AbstractRx) =>
  'pharmacy' in rx ? rx.require2Fa : rx.dispensableControlledSubstance;

export const mapPatientPrimaryAddressStateToString = ({
  data: {
    patient: {
      primaryAddress: { state },
    },
  },
}): string => state;

export const hasControlledSubstanceChanges = (
  a: AbstractRx[],
  b: AbstractRx[],
) => asComparable(a) !== asComparable(b);

const asComparable = (rxs: AbstractRx[]) =>
  JSON.stringify(rxs.filter(hasControlledSubstanceFilter));
